// extracted by mini-css-extract-plugin
export var animateBg = "Banner-module--animateBg--c0f9d";
export var buttonPadding = "Banner-module--buttonPadding--e1511";
export var claedly = "Banner-module--claedly--1e63e";
export var claedlyContainer = "Banner-module--claedlyContainer--8e77d";
export var claedlyDec = "Banner-module--claedlyDec--42757";
export var content = "Banner-module--content--e7343";
export var dec = "Banner-module--dec--94c62";
export var heading = "Banner-module--heading--c50ef";
export var ripple1 = "Banner-module--ripple1--83337";
export var ripple2 = "Banner-module--ripple2--f1a15";
export var ripple3 = "Banner-module--ripple3--59da2";
export var subHeading = "Banner-module--subHeading--0b456";
export var videoHeight = "Banner-module--videoHeight--4ba86";
export var waveShadow = "Banner-module--waveShadow--38b93";