import React from "react"
import * as styles from "./ClientLogos.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

const data = [
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Layer_0_1_bfd89fda40.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Layer_0_1_1_bf29e210ce.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Global_Reader_logo_1_99b2445628.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_e5ec111a33.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/x37_Ef_Idd_3_39bbd54a8c.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/image_16_86d385edad.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nggf_PX_3_f040ae16e7.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_46c910cbf6.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_3_7842338e3e.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Layer_0_1_2_6fd87014c6.png",
  },
]

const TrustInvoZone = () => {
  return (
    <div className={styles.Trust}>
      <Container>
        <div className={styles.border}>
          <p className={styles.grow}>
            <span> 400+ </span> products developed for <span> 300+ </span>
            customers across <span>120+ </span>countries.
          </p>
          <Row className={`${styles.rowGap}`}>
            {data.map((item, index) => (
              <div className={styles.logo}>
                <img src={item.logos} alt={`Client logo ${index + 1}`} />
              </div>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default TrustInvoZone
