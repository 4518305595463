import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import { Link } from "gatsby"

const Banner = () => {
  return (
    <div className={styles.section}>
      <div className={styles.bannerDeskHome}>
        <div className="bannerVideo">
          <div>
            <video
              autoPlay
              muted
              loop
              poster="https://invozone-backend.s3.us-east-1.amazonaws.com/hero_section_1_53984860ed.webp"
            >
              <source
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Home_Page_Video_Final_Format_8fe0b0e15f.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <p className={styles.subHeading}>
                Software Development Consulting
              </p>
              <h1 className={styles.heading}>Future-Driven Innovations.</h1>
              <p className={styles.dec}>
                Fostering Growth For Startups, Enterprises, And Innovators.
              </p>
              <div className={styles.claedlyContainer}>
                <div
                  className={styles.claedly}
                  style={{ position: "relative" }}
                >
                  <div className={styles.animateBg}>
                    <i className={styles.ripple1}></i>
                    <i className={styles.ripple2}></i>
                    <i className={styles.ripple3}></i>
                  </div>
                  <img
                    className={styles.waveShadow}
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/claendly_8606ad6fd7.svg"
                  />
                </div>
                <Link to={"https://calendly.com/invozone"}>
                  <p className={styles.claedlyDec}>Book a 15 min Call</p>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default Banner
