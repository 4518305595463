import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import * as styles from "./ClientLogosMobile.module.scss"

const Team = () => {
  const data = [
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Layer_0_1_bfd89fda40.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Layer_0_1_1_bf29e210ce.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Global_Reader_logo_1_99b2445628.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_e5ec111a33.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/x37_Ef_Idd_3_39bbd54a8c.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_16_86d385edad.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/nggf_PX_3_f040ae16e7.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_46c910cbf6.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_3_7842338e3e.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Layer_0_1_2_6fd87014c6.png",
    },
  ]
  let settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    // speed: 2000,
    slidesToShow: 6,
    // slidesToScroll: 1,
    // loop: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`${styles.wrap}`}>
      <div className={styles.outer}>
        <Slider {...settings} className={styles.sliderblock}>
          {data.map((slide, index) => (
            <div key={index} className={styles.ContentArea}>
              <div>
                <img
                  src={slide?.logos}
                  alt={`Slide ${index + 1}`}
                  id="decoding"
                  loading="lazy"
                  className="w-100 h-100"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Team;
